<template>
  <div class="app-wrapper">
    <ElMenu class="sidebar-container"/>
    <div class="main-container">
      <div class="header-main">
        <p>{{title}}</p>
        <el-button @click="loginOut" class="loginOutBtn" type="primary"
        >Exit the system
        </el-button
        >
      </div>
      <AppContent class="app-main"/>
    </div>
  </div>
</template>

<script>
import AppContent from "./components/AppContent.vue";
import ElMenu from "./components/ElMenu/index.vue";

export default {
  data() {

    return {
      title:process.env.VUE_APP_TITLE
    }

  },
  components: {
    AppContent,
    ElMenu,
  },
  methods: {
    loginOut() {
      localStorage.setItem("token", null);
      localStorage.setItem("account", null);
      window.location.href = "/login";
    },
  },
};
</script>

<style lang="less" scoped>
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  .sidebar-container {
    -webkit-transition: width 0.28s;
    transition: width 0.28s;
    width: 200px !important;
    background-color: #304156;
    height: 100%;
    position: fixed;
    font-size: 0px;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;
    -webkit-box-shadow: 2px 0 6px rgb(0 21 41 / 35%);
    box-shadow: 2px 0 6px rgb(0 21 41 / 35%);

    & > div {
      width: 211px !important;
    }
  }

  .main-container {
    min-height: 100%;
    -webkit-transition: margin-left 0.28s;
    transition: margin-left 0.28s;
    margin-left: 200px;
    position: relative;
  }

  .main-container {
    -webkit-transition: margin-left 0.28s;
    transition: margin-left 0.28s;
    position: fixed;
    width: calc(100vw - 210px);
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;

    .header-main {
      position: fixed;
      height: 60px;
      width: calc(100% - 200px);
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      // border-bottom: 1px solid #ddd;
      padding-left: 15px;
      padding-right: 50px;
      box-sizing: border-box;
      justify-content: space-between;
    }

    .app-main {
      min-height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden;
    }
  }
}
</style>
